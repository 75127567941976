/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import "./FormDateUser.css";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { recovery } from "../../services/consumeApi";
import { Alert, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, Snackbar } from "@mui/material";
import { Turnstile } from '@marsidev/react-turnstile'


const FormUser = (props) => {
  const [openAlertConnect, setOpenAlertConnect] = useState(false);
  const [status, setStatus] = useState()
  const [openModalFailedCaptcha, setopenModalfailedCaptcha] = useState(false);
  const [openAlertConnectSuccess, setOpenAlertConnectSuccess] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  let empty =
    props.cardDigits.length === 0

  useEffect(() => {
    if (status === "error") {
      setopenModalfailedCaptcha(true)
    }
  }, [status]);

  const handleSendEmail = async () => {
    const res = await recovery(props.tkp, props.valid, props.email, "recuperar");
    if (res.status === 200) {
      if (res.data.estatus === "success") {
        setOpenAlertConnectSuccess(true)
      } else {
        setOpenAlertConnect(true)
      }
    }
  }

  return (
    <>
      <form className="form-card" onSubmit={handleSubmit(props.on_Submit)}>
        <div className="form-input">
          <TextField
            {...register("card", {
              required: {
                value: true,
                message: "Este campo es obligatorio",
              },
            })}
            id="standard-number"
            label="Ingresa últimos 4 digitos de tarjeta con la que te hiciste socio"
            variant="standard"
            value={props.cardDigits}
            type="number"
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 4);
            }}
            onChange={(event) => props.setCardDigits(event.target.value)}
            InputProps={{
              endAdornment: (
                <lord-icon
                  src="https://cdn.lordicon.com/cgpyuuzu.json"
                  trigger="hover"
                  colors="primary:#3a3347,secondary:#4d39e9,tertiary:#a39cf4,quaternary:#ebe6ef"
                  scale="70"
                ></lord-icon>
              ),
            }}
          />
          <p className="errorStyle">{errors?.card?.message}</p>
        </div>
        <div className="section-button">
          <div className="slider-captcha">
            <div id="captcha">
              <Turnstile
                options={{ size: "normal" }}
                siteKey={process.env.REACT_APP_TOKEN_CAPTCHA}
                onError={() => setStatus("error")}
                onExpire={() => setStatus("expired")}
                onSuccess={() => setStatus("solved")}
              />
            </div>
          </div>
          <div className="button">
            <Button
              id="button-card"
              type="submit"
              disabled={empty}
            >
              Continuar
            </Button>
          </div>
          <div className="buttonRecovery">
            <div onClick={handleSendEmail}>
              No recuerdo mis 4 últimos dígitos
            </div>
          </div>
        </div>
        <Snackbar
          open={openAlertConnectSuccess}
          autoHideDuration={3000}
          onClose={() => setOpenAlertConnectSuccess(false)}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Se han enviado los 4 dígitos a su correo electrónico.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openAlertConnect}
          autoHideDuration={3000}
          onClose={() => setOpenAlertConnect(false)}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Ha ocurrido un error, por favor intente mas tarde.
          </Alert>
        </Snackbar>
        <Dialog
          className="captchaError"
          open={openModalFailedCaptcha}
        >
          <DialogContent>
            <div className="captchaIcon">
              <lord-icon
                src="https://cdn.lordicon.com/inrunzby.json"
                trigger="hover"
                colors="primary:#ffc738,secondary:#4f1091"
                scale="80"
              ></lord-icon>
            </div>
            <div className="error">
              <h2>Lo siento, no podemos procesar su solicitud.</h2>
              <h4>Presione ACEPTAR para volver a intentar. </h4>
            </div>
            <DialogActions>
              <Button onClick={() => window.location.reload(false)}>
                Aceptar
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </form>
    </>
  );
};

export default FormUser;
