import React, { useState } from "react";
import "./CardModalChange.css";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";


const columns = [
    {
        id: "fechacobro",
        label: "Fecha de cobro Planificada",
        minWidth: 80,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "fecharegistro",
        label: "Fecha de cobro Efectiva",
        minWidth: 80,
        align: "right",
        format: (value) => value.toFixed(2),
    },
    {
        id: "estatus",
        label: "Estado",
        minWidth: 80,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "amount",
        label: "Monto cobrado",
        minWidth: 80,
        align: "right",
        format: (value) => value.toFixed(0),
    },

];

const CardModalChange = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const rows = props.servicesCobros?.cobros;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    defineLordIconElement(loadAnimation);
    return (
        <>
            <Dialog className="modalTable" open={props.isOpenTable} >
                <DialogContent >
                    <div className="title">
                        <h1>Historial de tus aportes</h1>
                    </div>
                    <div className="container-table-cobros">
                        <Paper sx={{ width: "100%", overflow: "hidden" }}>
                            <TableContainer sx={{ maxHeight: 640 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow className="t-head">
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ top: 57, minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="t-body">
                                        {
                                            props.servicesCobros?.cobros?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, rowId) => {
                                                    return (
                                                        <TableRow hover role="checkbox" key={rowId}>
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        {column.label === "Opciones" ? (
                                                                            <>
                                                                            </>
                                                                        ) : column.format && typeof value === "number" ? (
                                                                            column.format(value)
                                                                        ) : value === "activo" ? (
                                                                            <Chip label={value} color="primary" />
                                                                        ) : (
                                                                            value
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 15, 30]}
                                component="div"
                                count={rows?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={"Fila Por Página"}
                            />
                        </Paper>
                    </div>
                    <DialogActions>
                        <Button onClick={props.isClose}>Aceptar</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default CardModalChange;