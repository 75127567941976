import React, { useState, useContext } from "react";
import InfoContext from "../../Context";
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog'
import FormUser from '../../components/formDateUser/FormUser'
import { postInfoUsers } from "../../services/consumeApi";
import "./Form.css";

const Form = () => {
  const [open, setOpen] = useState(false);
  const [cardDigits, setCardDigits] = useState('');
  const [dialog, setDialog] = useState(false);
  const [CodeError, setCodeError] = useState(false);


  const navigate = useNavigate();
  const { setUsers } = useContext(InfoContext);

  const wlocation = window.location.search;
  const urlParams = new URLSearchParams(wlocation);
  const email = urlParams.get("email");
  const tkp = urlParams.get("tkp");
  const valid = urlParams.get("valid");
  const background = urlParams.get('backgroundColor')
  document.body.style.backgroundColor = `#${background}`
  const navigateActive = () => {
    navigate('/suscripcion');
  }

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
    setCardDigits('');
    setCodeError(false)
  };


  const on_Submit = async () => {
    const resUser = await postInfoUsers(tkp, valid, email, parseInt(cardDigits))
    const initialValues = {
      emailUrl: email,
      tkpUrl: tkp,
      validUrl: valid,
      cardDigits: cardDigits,
      data: resUser.data.subscriptions,
      colorGlobal: background
    };

    if (resUser.status === 200) {
      let dataSuscripcion = { 'data': initialValues }
      setUsers(initialValues)
      sessionStorage.setItem('dataSuscripcion', JSON.stringify(dataSuscripcion))
      setOpen(false);
      if (resUser.data.status === 'failed') {
        setCodeError(true)
      } else {
        navigateActive()
      }
    }
  }

  defineLordIconElement(loadAnimation);

  return (
    <div className="content-form">
      <div className="card-content">
        <div className="container-card">
          <div className="body-card">
            <div className="form-title">
              <p className="text-data">Completa el siguiente campo:</p>
            </div>
            <FormUser
              on_Submit={on_Submit}
              cardDigits={cardDigits}
              setCardDigits={setCardDigits}
              tkp={tkp}
              valid={valid}
              email={email}
            />
          </div>
        </div>

        <Dialog
          open={dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="contentErrorPadre"
        >
          <DialogContent>
            <DialogContentText >
              Los datos introducidos son incorrectos. Por favor, intenta de nuevo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Aceptar</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={CodeError}
          className="contentErrorPadre"
        >
          <DialogContent>
            <DialogContentText >
              <p>Los dígitos introducidos son incorrectos. Por favor, intenta de nuevo.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>

  );
};
export default Form;