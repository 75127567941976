import React from 'react';

const NotFoundPage = () => {
    return (
        <>
            <p>404 Pagina no encontrada</p>
        </>
    )
}

export default NotFoundPage