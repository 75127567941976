import React, { useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, Snackbar } from '@mui/material'
import { defineLordIconElement } from 'lord-icon-element';
import { loadAnimation } from "lottie-web";
import "./AlertEdit.css"

function AlertEdit(props) {

    defineLordIconElement(loadAnimation);

    return (
        <>
            <Dialog
                className="modalSucess"
                open={props.openModalUpdateSuccess}
            >
                <DialogContent>
                    <div className="iconSuccess">
                        <lord-icon
                            src="https://cdn.lordicon.com/sxddhwcs.json"
                            trigger="hover"
                            colors="primary:#4D39E9,secondary:#ebe6ef"
                            stroke="80"
                            scale="50"
                        >
                        </lord-icon>
                        <p>¡Sus datos han sido actualizados con éxito!</p>
                    </div>
                    <DialogActions>
                        <Button onClick={props.UpdateSuccess}>Aceptar</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        <Snackbar
            open={props.openUpadateFailed}
            autoHideDuration={3000}
        >
        <Alert severity="error" sx={{ width: "100%" }}>
          Ha ocurrido un error, por favor intente mas tarde.
        </Alert>
      </Snackbar>


        </>
    )
}

export default AlertEdit