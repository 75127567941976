import './App.css';
import Form from './pages/Form/Form'
import Suscripcion from './pages/Suscripcion/Suscripcion.jsx';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import { Routes, Route, BrowserRouter } from "react-router-dom";

function App() {

    return (
        <BrowserRouter >
            <Routes>
                <Route path="/" element={< Form />} />
                <Route path="/suscripcion" element={< Suscripcion />} />
                <Route path="*" element={< NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
}
export default App;