import React, { useState, useContext, useEffect } from "react";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import "./Modal.css"


const Modal = (props) => {
  const [valuesInputs, setValuesInputs] = useState({
    phone: props.phoneUser,
    amount: props.montoUser
  })
  const {
    register,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onBlur",
  });


  const ArrowForwardIcon = {
    sx: {
      ".MuiPickersArrowSwitcher-button": {
        display: "none"
      }
    }
  };
  const disabled = !isDirty && !isValid || props.errorMonto.length !== 0;

  console.log('valuesInputs', valuesInputs)
  return (
    <>
      <div className="editData">
        <Dialog className="modalEdit" open={props.isOpen}>
          <DialogContent>
            <h2>Actualiza tus datos personales:</h2>
            <TextField
              {...register("email", {
                required: {
                  value: true,
                  message: "El campo no puede estar vacío",
                },
              })}
              margin="dense"
              id="name"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={props.dataUser.email}
              //value={props.emailUser}
              onChange={(event) => props.validateEmail(event)}
            />
            <TextField
              margin="dense"
              id="name"
              label="Teléfono"
              type="number"
              fullWidth
              variant="standard"
              defaultValue={props.dataUser.phone}
              value={props.phoneUser}
              onChange={(event) => {props.phoneEdit(event.target.value)}}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 9);
              }}
            />
            <p className="errorStyle"></p>
            <h2 className="title-two">Actualizar monto de tu aporte:</h2>
            <TextField
              margin="dense"
              id="name"
              label="Monto"
              type="number"
              fullWidth
              variant="standard"
              defaultValue={props.dataUser.amount}
              value={props.montoUser}
              onChange={(event) => props.validateMonto(Number(event.target.value))}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
            />
            <p className="errorStyle"> {props.errorMonto}</p>
            <div className="fecha">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  readOnly
                  inputFormat="MM-dd-yyyy"
                  label="Próximo Pago"
                  openTo="day"
                  PaperProps={ArrowForwardIcon}
                  value={
                    props.date === undefined
                      ? props.dataUser.datepaymentauto
                      : props.date
                  }
                  renderInput={(params) => (
                    <TextField {...params} error={false} />
                  )}
                  PopperProps={{
                    placement: "bottom-start",
                  }}
                  showDaysOutsideCurrentMonth={true}
                  onChange={(newDate) => {
                    props.setDate(newDate);
                  }}
                  views={["day"]}
                />
              </LocalizationProvider>
            </div>
            <p className="pFecha">
              <i>
                <u>Nota:</u> Si deseas cambiar tu mail o la fecha de tu próximo
                cobro, escríbenos a <strong> socios@ciperchile.cl</strong>
              </i>
            </p>
            <div className="sectionButton">
              <DialogActions>
                <Button onClick={()=>{
                  props.phoneEdit(setValuesInputs.phone);
                  props.validateMonto(setValuesInputs.amount);
                  props.isCloseEdit()
                }}>Cancelar</Button>
                <Button disabled={disabled} onClick={props.UpdateEdit}>
                  Aceptar
                </Button>
              </DialogActions>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default Modal