import { createContext, useState } from "react";

const InfoContext = createContext();

export function InfoProvider({ children }) {
    let data = JSON.parse(sessionStorage.getItem('dataSuscripcion'))
    let datos = [];
    if (data) {
        datos = data.data ? data?.data : [];
    }
    const [users, setUsers] = useState(datos);
    return (
        <InfoContext.Provider value={{ users, setUsers, }}>
            {children}
        </InfoContext.Provider>

    );
}

export default InfoContext;